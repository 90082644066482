<template>
  <div>
    <el-card>
      <!-- 错误码统计 -->
      <!-- 查询条件 -->
      <!-- <el-collapse>
        <el-collapse-item :title="$t(`展开搜索框`)" name="1">
          <div> -->
      <el-form :model="searchForm" inline ref="searchFormRef">
        <el-form-item :label="$t('时间段')" prop="">
          <el-date-picker
            style="width: 150px"
            v-model="searchForm.startTime"
            type="date"
            :placeholder="$t('开始时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
          -
          <el-date-picker
            v-model="searchForm.endTime"
            style="width: 150px"
            type="date"
            :placeholder="$t('结束时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
        </el-form-item>
        <el-form-item :label="$t('错误码')" prop="">
          <el-input
            v-model.trim="searchForm.errorCode"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.errorCode = ''"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item :label="$t('用户账号')" prop="">
                <el-input
                  v-model.trim="searchForm.userName"
                  :placeholder="$t('请输入')"
                  clearable
                  @clear="searchForm.userName = ''"
                  style="width: 200px"
                />
              </el-form-item> -->
        <el-form-item :label="$t('应用编号')" prop="">
          <el-input
            v-model.trim="searchForm.appId"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.appId = ''"
            style="width: 200px"
          />
        </el-form-item>

        <!-- <el-form-item :label="$t('通道名称')" prop="">
                <el-input
                  v-model.trim="searchForm.channelName"
                  :placeholder="$t('请输入')"
                  clearable
                  @clear="searchForm.channelName = ''"
                  style="width: 200px"
                />
              </el-form-item> -->

        <!-- <el-form-item :label="$t('通道编号')" prop="">
                <el-input
                  v-model.trim="searchForm.channelSn"
                  :placeholder="$t('请输入')"
                  clearable
                  @clear="searchForm.channelSn = ''"
                  style="width: 200px"
                />
              </el-form-item> -->
        <el-form-item :label="$t('运营商')" prop="">
          <el-select
            v-model="searchForm.operator"
            placeholder=""
            clearable
            allow-create
            default-first-option
            @clear="searchForm.operator = null"
            style="width: 200px"
          >
            <el-option
              v-for="(item, key) in dic.operator"
              v-show="
                item.value === 10 || item.value === 20 || item.value === 30
              "
              :key="key"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="" prop="">
          <!-- <el-switch
                  v-auth="btnAuth.searchMode"
                  v-model="searchForm.searchMode"
                  :active-value="1"
                  :inactive-value="0"
                  style="margin: 0 30px"
                >
                </el-switch> -->
          <el-button type="primary" @click="query"
            ><i class="fa-solid fa-magnifying-glass"></i
            >{{ $t("查询") }}</el-button
          >
          <el-button type="primary" @click="exportExcel" :loading="btnLoading"
            ><i class="fa-solid fa-download"></i>{{ $t("导出数据") }}</el-button
          >
        </el-form-item>
      </el-form>
      <!-- </div>
        </el-collapse-item>
      </el-collapse> -->

      <!-- 表格 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        class="elTable"
        height="var(--height)"
        v-loading="loading"
      >
        <template #empty>
          <el-empty description="" />
        </template>
        <el-table-column
          prop="errorCode"
          :label="$t('失败回执')"
          align="center"
        />
        <el-table-column
          prop="sendCount"
          :label="$t('发送数量')"
          align="center"
        />
      </el-table>
      <!-- 分页 -->
      <div style="margin: 0 auto; overflow-x: auto; padding: 20px 0">
        <el-pagination
          v-model:currentPage="searchForm.page"
          v-model:page-size="searchForm.pageSize"
          :page-sizes="[10, 50, 100, 200]"
          :disabled="false"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="query"
          @current-change="query"
        />
      </div>
    </el-card>
  </div>
</template>
  <script>
export default {
  name: "errorCodeStatistics",
};
</script>
  <script setup>
import dayjs from "dayjs";
import { reactive, onBeforeMount, ref } from "vue";
import {
  getErrorCodeStatisticsList,
  exportErrorCodeStatisticsList,
} from "@/http/api/statisticalManage.js";
// import { getChannelList } from "@/api/channelManage.js";
// import { getSmsCustomerList } from "@/api/customerManage.js";
import { binaryToExcelExport } from "@/utils/tool.js";
import dic from "@/utils/dictionary";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
// 查询表单
const searchForm = reactive({
  startTime: dayjs().startOf("date").format(),
  endTime: dayjs().startOf("date").format(),
  errorCode: "",
  userName: "",
  appId: "",
  channelSn: "",
  channelName: "",
  operator: null,
  searchMode: 0,
  page: 1,
  pageSize: 100,
});
// 表格数据
const loading = ref(true);
const tableData = ref([]);
let total = ref(0);
// 获取字典
let optionsObj = reactive({ operator: [] });
onBeforeMount(async () => {
  query();
});
const query = async () => {
  loading.value = true;
  const res = await getErrorCodeStatisticsList(searchForm);
  if (res.code === 0) {
    tableData.value = res.data.list || [];
    total.value = res.data.total;
  }
  loading.value = false;
};
//===============================导出数据===============================
const btnLoading = ref(false);
const exportExcel = async () => {
  btnLoading.value = true;
  const res = await exportErrorCodeStatisticsList(searchForm);
  if (res && res instanceof Blob) {
    binaryToExcelExport(res, t("错误码统计报表"));
  }
  btnLoading.value = false;
  // if (res.status === 200 && res.statusText === "OK") {
  //   binaryToExcelExport(res.data, t("错误码统计报表"));
  // }
};
</script>
  
  <style lang="scss" scoped>
.elTable {
  --height: calc(100vh - 250px);
}
::v-deep(label) {
  margin-bottom: 0;
}
</style>
  